import * as React from "react"
import { graphql } from "gatsby"

// components
import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactUsHeroSection from "../components/ContactUsHeroSection"
import { useTranslation } from "gatsby-plugin-react-i18next"
const ContactUs = () => {
  const { t } = useTranslation()
  return (
    <Layout page="contact" footerBgVariant="dark" currentpage={t("contactNav")}>
      <Seo title="Contact" />
      <ContactUsHeroSection />
    </Layout>
  )
}

export default ContactUs

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
