import React from "react"
import { Trans } from "react-i18next";

// helpers
import { pageDirection } from "../../helpers/utils";

// styles
import "./ContactUsHeroSection.css";

const ContactUsHeroSection = () => {
  const dir = pageDirection();
  return (
    <div dir={dir} className="container text-center contact-us-page-section">
      <h3>
        <Trans>contactSectionHeading</Trans>
      </h3>
      <p className="sub-text">
        <Trans>contactSectionMain</Trans>{" "}
        <a href="mailto:help@trysamar.com" className="mailto help">help@trysamar.com</a>
      </p>
      <div className="row justify-content-between">
        <div className="col-md-6 text-center">
          <p>
            <Trans>contactSectionPartnership</Trans><br />
            <a href="mailto:partnership@trysamar.com" className="partnership mailto">partnership@trysamar.com</a>
          </p>
        </div>
        <div className="col-md-6 text-center">
          <p>
            <Trans>contactSectionPress</Trans><br />
            <a href="mailto:press@trysamar.com" className="press mailto">press@trysamar.com</a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default ContactUsHeroSection
